<template>
    <!-- 网络错误 -->
    <van-empty :image="image" :description="errorMsg">
        <van-button v-if="backBtn" @click="goBack()" round type="danger" class="bottom-button">
            {{backBtn}}
        </van-button>
    </van-empty>
</template>

<script>
    export default {
      // eslint-disable-next-line vue/multi-word-component-names
        name: 'index',
        data() {
            return {
                errorMsg: '未找到该页面，请重试',
                backBtn: '',
                image: 'search'
            }
        },
        methods: {
            goBack(url) {
                if (url) {
                    this.$router.replace(url)
                } else {
                    this.$router.go(-1)
                }
            }
        },
        mounted() {
            console.log(this.$route.params.type)
            let type = this.$route.params.type;
            switch (+type) {
                case 1:
                    this.errorMsg = '人脸验证不通过，请重试'
                    this.image = 'error'
                    this.backBtn = '点此返回'
                    break;
                default:
                    break;
            }
        }
    }
</script>

<style scoped>
    .bottom-button {
        width: 160px;
        height: 40px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        box-shadow: 0px 8px 10px 0px rgba(60, 165, 250, 0.3);
    }
</style>
