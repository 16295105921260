let golbal = {
  DOMAIN_URL: process.env.NODE_ENV === 'production' ? '//py.wzlzw.gov.cn' : 'http://www.py.cn',
  // https://wzccjj.wzcc.com/
  //缓存 是否已经配置微信jssdk
  // SignPackage: false,
  // //emun 枚举
  // Response_Type: [{value: 1, text: '追尾'}, {value: 2, text: '刮擦'}, {value: 10, text: '其他'}],
  // CarCount: [{value: 1, text: '1辆'}, {value: 2, text: '2辆'}, {value: 3, text: '3辆'}, {value: 4, text: '4辆'}],
  // workType: [{value: 1, text: '工作日'}, {value: 2, text: '休息日'}],
  // workStatus: [{value: -1, text: '已作废'}, {value: 0, text: '待完善'}, {value: 1, text: '待审核'}, {
  //   value: 2,
  //   text: '已通过'
  // }, {value: 3, text: '驳回'}],
}

export default golbal;
