<template>
  <div class="bg">
    <div class="container">
      <div class="title">
        <div class="custom-department">{{ model.departmentName[0] }}</div>
        <div class="custom-subdepartment">{{ model.departmentName[1] }}</div>
        <van-form @submit="onSubmit">
          <div v-bind:key="key" v-for=" (title,key) in titles">
            <h5 content-position="left" class="answer-title">您对该处室<span>{{ title }}</span>情况评价为：</h5>

<!--            <h4 class="answer-title">你对该处室<span>{{ title }}</span>情况评价为：</h4>-->
            <van-row gutter="20">
              <van-col span="8">
                <van-button native-type="button" @click="changeBtn(1,key+1)" :plain="selectedData[key+1]!==1" hairline
                            :color="selectedData[key+1]!==1?'#FF862C':'#FF862C'" size="small"
                            type="primary" block>
                  满意
                </van-button>
              </van-col>
              <van-col span="8">
                <van-button native-type="button" @click="changeBtn(2,key+1)" :plain="selectedData[key+1]!==2" hairline
                            :color="selectedData[key+1]!==2?'#FF862C':'#FF862C'" size="small"
                            type="primary" block>
                  一般
                </van-button>
              </van-col>
              <van-col span="8">
                <van-button native-type="button" @click="changeBtn(3,key+1)" :plain="selectedData[key+1]!==3" hairline
                            :color="selectedData[key+1]!==3?'#FF862C':'#FF862C'" size="small"
                            type="primary" block>
                  不满意
                </van-button>
              </van-col>
            </van-row>
          </div>

          <div class="van-hairline--top"></div>


          <div class="box">
            <div class="col">
              <div style="margin-bottom: 5px;" v-if="this.showContent"> 您如需其他反馈请留言：</div>
              <van-field
                  v-model="form.replyContent"
                  class="textarea"
                  maxlength="600"
                  show-word-limit
                  autosize
                  style="background:#f4f1f1"
                  label=""
                  :readonly="mode=='read'"
                  type="textarea"
                  placeholder="请输入不满意原因"
                  v-if="this.showContent"
              />
            </div>
            <div class="col" style="margin-bottom: 20px;">
              <van-button :disabled="mode=='read'" native-type="submit" loading-text="提交中..." :loading="loading"
                          class="btn" color="#EA3144"
                          type="primary"
                          size="small">
                {{ mode == 'read' ? '已评' : '确认提交' }}
              </van-button>
            </div>

          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import {getDetail, submitFormBatch} from "@/api/paper";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "detail",
  data() {
    return {
      model: {},
      titles: ['落实中心工作', '优化服务效能', '正确履行职责', '落实基层减负', '严守法律纪律'],
      selectedData: {1: 1, 2: 1, 3: 1, 4: 1, 5: 1},
      mode: '',
      showContent: false,
      loading: false,
      form: {
        dictId: {1: 1, 2: 1, 3: 1, 4: 1, 5: 1},
        replyContent: '',
        randomId: undefined,
        code: undefined
      }
    }
  }, methods: {
    checkTextArea(){
      let showContent = false;
      for (let key in this.selectedData) {
        console.log('read', key, this.selectedData[key],showContent)
        if (this.selectedData[key] === 3 && showContent === false) {
          showContent = true
          break;
        } else {
          showContent = false
        }
      }
      this.showContent = showContent
    },
    changeBtn(option, key) {
      console.log('key', key, this.selectedData[+key])
      if (this.mode == 'read') {
        return false;
      }
      console.log('change', this.selectedData, this.form.dictId)
      this.form.dictId[key] = option
      this.selectedData[key] = option
      this.checkTextArea()
    }, onSubmit() {
      // console.log('form',this.form)
      if (this.mode == 'read') {
        return false;
      }


      if (!this.form.dictId) {
        this.$dialog.alert({
          message: '请选择对部门的满意度后再提交！',
        }).then(() => {
          // on close
        });
        return false;
      }
      this.$dialog.confirm({
        title: '温馨提示',
        message: '请确认评价结果，后续将无法修改！',
      })
          .then(() => {
            this.loading = true
            this.$toast.loading({
              message: '数据提交中...',
              forbidClick: true,
            });

            // if (this.form.dictId == 3 && !this.form.replyContent) {
            //   this.$dialog.alert({
            //     message: '请输入不满意理由',
            //   }).then(() => {
            //     // on close
            //   });
            // }
            submitFormBatch(this.form).then(res => {
              if (res.success == true) {
                this.$dialog.alert({
                  message: '评价完成，请点击“确认”返回列表',
                }).then(() => {
                  // on close
                  this.$router.push('/list/' + this.form.code)
                });
              } else {
                this.$dialog.alert({
                  message: res.message,
                }).then(() => {
                  // on close
                });
              }
            })
          })
          .catch(() => {
            // on cancel
          });


    }
  },
  mounted() {
    let code = localStorage.getItem('codeTicket')
    let id = this.$route.params.id;
    if (!code) {
      this.$route.push('/error')
    }
    getDetail(id, code).then((res) => {
      // console.log(res)
      if (res.code == 200) {
        // this.form.code = code
        // this.form.randomId = id
        // this.form.dictId = res.data.dictId
        let dictId = this.form.dictId
        console.log('dictId', dictId, res.data.dictId)
        this.form = {
          code,
          randomId: id,
          dictId: res.data.dictId ? res.data.dictId : dictId,
          replyContent: res.data.replyContent
        }

        this.selectedData = res.data.dictId ? res.data.dictId : dictId
        this.mode = (res.data.status == 1) ? 'read' : 'edit';
        this.model = res.data
        this.checkTextArea()
      } else {
        this.$toast(res.data.message)
      }
    })
  }
}
</script>

<style scoped>
.answer-title {
  color: #7D7D7D;
}

.answer-title span {
  color: red;
}

.bg {
  background: #FF8D21;
  overflow-y: auto;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  position: relative;
}

.title .custom-department {
  color: #7D7D7D;
  font-size: 14px;
  line-height: 25px;
}

.title .custom-subdepartment {
  color: #000000;
  font-size: 16px;
  line-height: 25px;
  font-weight: bolder;
}

.title {
  width: 90%;
  margin: 0 auto;
}

.container {
  padding: 15px;
  width: 80%;
  margin: 0 auto;
  background: #FFFFFF;
  border-radius: 16px;
}

.textarea {
  background: #BFBDBD;
  min-height: 100px;
  color: white;


}

/deep/ :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #000;
  opacity: 1;
}

/deep/ ::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #000;
  opacity: 1;
}

/deep/ input:-ms-input-placeholder {
  color: #000;
  opacity: 1;
}

/deep/ input::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}

.col {
  width: 100%;
  margin-top: 30px;
}

.btn {
  margin: 20px auto;
  width: 100px;
  display: flex;
  display: -webkit-flex; /* Safari */
  justify-content: center;
  /* float: left; */
}

.box {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /*align-items:center;*/
}
</style>
