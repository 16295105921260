import request from '@/requset'

export function bindMobile(code) {
  return request.post('/web/default/bind', {code})
}

export function getDetail(id, code) {
  return request.post('/web/default/detail', {id,code})

}

export function submitForm(form) {
  return request.post('/web/default/submit', {form})

}

export function submitFormBatch(form) {
  return request.post('/web/default/submit', {form})

}