import List from '../view/paper/list'
import Detail from '../view/paper/detail'

const shoot = [
    {
        name: 'list',
        // redirect:'/quick/first',
        path: '/list/:code',
        component: List,
        meta: {
            title: '机关中层类履职成效评价'
        }
    },
    {
        name: 'detail',
        path: '/detail/:id',
        component: Detail,
        meta: {
            title: '机关中层类履职成效评价详情'
        }
    }
];

export default shoot