import axios from 'axios'
import global from './global'

// axios.defaults.baseURL = process.env.BASE_API
// axios.defaults.baseURL = global.DOMAIN_URL

const service = axios.create({
    baseURL:global.DOMAIN_URL,
    timeout: 5000,
    withCredentials: true,
    headers: {
       // 'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=UTF-8'
    }
})
service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        return res
    },
    error => {
        console.log(error)
        return error.response.data
    }
)
export default service
