<template>
  <div class="bg">
    <!--<img src="@/assets/images/banner.png" style="width: 100%" alt="">-->
    <div class="container">
      <div class="notice">
        <div class="title"><b style="font-size: 14px;">规则介绍</b> <br>
          1、每个对应手机号码限投一次；<br>
          2、需对以下机关中层参评处室（站所）逐一进行评价。
        </div>
      </div>
      <div class="list">
        <div class="item" v-if="list.length>0">
          <van-cell @click="redirectItem(item)" v-for="item in list" :key="item.randomId" center is-link>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="department">{{item.departmentName[0]}}</span>
            </template>
            <template #label>
              <span class="subdepart">{{item.departmentName[1]}}</span>
            </template>
            <template #default>
              <van-tag v-if="item.status==1" type="warning">已评</van-tag>
            </template>
          </van-cell>
        </div>
        <div v-else>
          <van-empty image="search" description="正在加载数据……"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {bindMobile} from "@/api/paper";

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "list",
    data() {
      return {
        list: []
      }
    }, methods: {
      redirectItem(item) {
        this.$router.push('/detail/' + item.randomId)
      }
    },

    mounted() {
      let code = this.$route.params.code;

      // console.log(this.$route.params.code)
      if (!code) {
        this.$route.push('/error')
      }
      bindMobile(code).then((res) => {
        // console.log('res', res)
        if (res.code == 200) {
          localStorage.setItem('codeTicket', code);
          this.list = res.data
        } else {
          this.$toast(res.data.message)
        }
      }).catch(error => {

        console.log('error', error)
      })
    }
  }
</script>

<style scoped>
  .bg {
    background: #FF8D21 url("../../assets/images/banner.png") no-repeat;
    background-size: 100%;
    /*background-position: center;*/
    padding-top: 166px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
  }

  .container {
    padding: 15px;
    width: 86%;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 16px;
  }

  .notice {
    background: #FBF6EF;
    border: 1px solid #FF862C;
    min-height: 29px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #A35000;

  }

  .title {
    margin: 15px;
    margin-top: 10px;
  }

  .department {
    font-size: 14px;
    color: #7D7D7D;
  }

  .subdepart {
    font-size: 16px;
    font-weight: bolder;
    color: black;
  }


</style>
