import Vue from 'vue'
import Router from 'vue-router'
import Error from '../view/error'

Vue.use(Router)

import paper from "./paper";

const baseRoutes = [
  {
    path: '*',
    redirect: '/error'
  },
  {
    //认证逻辑
    name: 'error',
    path: '/error',
    component: () => Error,
    meta: {
      title: '系统出错'
    }
  },
  {
    //认证逻辑
    name: 'error1',
    path: '/error/:type',
    component: () => Error,
    meta: {
      title: '系统出错'
    }
  }
]
const routes = [
  ...baseRoutes,
  ...paper
]

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
  routes
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export default  router;
